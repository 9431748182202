import React from 'react'
import PropTypes from "prop-types"
import Layout from '../components/Layout/Layout'
import SEO from "../components/seo"
// import Helmet from "react-helmet"

const ContactPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Contact" />
      <script src="https://www.google.com/recaptcha/api.js?render=6Ld1V-cUAAAAAKuynsr4kcbAhCa0QXxJZWZrXyFU"></script>
      <section>
        <div className="container mx-auto px-8">
          <div className="page-title">Contact Total Bike</div>
          <form method="post" action="https://getform.io/f/a2a604a4-af10-488f-ba63-4b41ae549c48">
            <div className="mb-3">
              <label className="block text-gray-700 text-md font-bold mb-2">Email</label>
              <input
                type="email"
                name="email"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-3">
              <label className="block text-gray-700 text-md font-bold mb-2">Name</label>
              <input
                type="text"
                name="name"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-3">
              <label className="block text-gray-700 text-md font-bold mb-2">Message</label>
              <textarea
                type="text" name="message"
                className="shadow appearance-none border rounded w-full h-56 py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="g-recaptcha mb-3" data-sitekey="6Ld1V-cUAAAAAKuynsr4kcbAhCa0QXxJZWZrXyFU"></div>
            <div className="mb-3">
              <input type="submit" value="Send" className="btn btn-blue" />
            </div>
          </form>
        </div>
      </section>
    </Layout>
  )
}

ContactPage.propTypes = {
  location: PropTypes.object
};

export default ContactPage;